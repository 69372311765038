import React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import { ArrowCircleRightIcon } from "@heroicons/react/outline";

import Header from "src/components/headers/header";
import Ariane from "src/components/headers/ariane";
import Footer from "src/components/footers/footer";

// import Engagement from "../../components/shortcodes/Engagement";
// import Cta from "../../components/shortcodes/Cta";
// import Presse from "../../components/shortcodes/Presse";
// import Prix from "../../components/shortcodes/Prix";
// import CtaCommande from "../../components/shortcodes/CtaCommande";
//
// const shortcodes = { Engagement, Cta, Presse, Prix, CtaCommande };

const mon_fil = ["Boutique", "/boutique/", "Cartons", "/boutique/cartons/"];

const MdxPage = () => {
  return (
    <div className="bg-gray-50">
      <Helmet>
        <title>
          Carton barrel et croisillons de déménagement | art Moval Lyon
        </title>
        <meta
          name="description"
          content="Vos cartons pour déménager | Kits déménagement, carton barrrel pour verres et assiettes, cartons penderie, cartons renforcés pour objets fragiles | art Moval Lyon"
        />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white shadow-xl">
        <Header />
      </header>

      <div class="relative max-w-7xl mx-auto h-full lg:flex">
        <main className="">
          <Ariane fil_ariane={mon_fil} />
          <div class="flex flex-1 overflow-hidden pr-4">
            <div class="flex flex-1 flex-col">
              <div class="flex flex-1 bg-gray-50 overflow-y-auto paragraph">
                <div className="relative bg-gray-50 overflow-hidden">
                  <div className="relative px-4 sm:px-6 lg:px-4">
                    <div className="text-lg mx-auto">
                      <h1>
                        <span className="mt-8 mb-4 block text-5xl text-left leading-8 font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-6xl">
                          Nos cartons
                        </span>
                      </h1>
                      {/* <p className="mt-8 mb-8 text-xl text-art-marron-fonce leading-8">
                            {excerpt}
                          </p> */}
                      {/* <div className="mt-8 text-xl flex-none text-art-orange">
                        Nos catégories
                      </div> */}
                      <ul
                        className="lg:flex mt-6 lg:space-x-3 text-xl leading-6 text-gray-600"
                      >
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/cartons/">Cartons</a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/accessoires-emballage/">
                            Accessoires d'emballage
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/manutention-transport/">
                            Manutention et transport
                          </a>
                        </li>
                        <li className="flex gap-x-3">
                          <ArrowCircleRightIcon
                            className="h-6 w-6 flex-none text-art-orange"
                            aria-hidden="true"
                          />
                          <a href="/boutique/kit/">Kits</a>
                        </li>
                      </ul>
                    </div>

                    {/* <div className="mx-auto max-w-2xl pb-16 pt-10 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:pb-24 lg:pt-16">
                      <div className="col-span-1">
                        <div className="rounded-3xl shadow-lg bg-gray-900/5 p-4 ring-1 ring-inset ring-gray-900/10 lg:rounded-3xl"></div>
                      </div>
                      <div className="col-span-2 prose-h2:text-4xl prose-h3:text-3xl mt-6 max-w-none prose-a:text-art-bleu prose-headings:text-art-marron-fonce prose prose-blue prose-lg text-art-marron-fonce mx-auto">
                        test
                      </div>
                    </div> */}

                    <div className="text-lg mx-auto">
                      <div className="mx-auto max-w-2xl py-4 lg:max-w-7xl">
                        {/* <h2 className="text-2xl font-bold tracking-tight text-art-marron-fonce">
                          Voir nos kits
                        </h2> */}

                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                          <div className="group relative">
                            <a href="/boutique/cartons/barrel-petit-modele-assiette/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel petit modèle assiettes"
                                    src="../../markdown-pages/barrel-petit-modele-assiette.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Barrel petit modèle assiettes
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  19,50€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/barrel-petit-modele-verre/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel petit modèle verres"
                                    src="../../markdown-pages/barrel-petit-modele-verre.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Barrel petit modèle verres
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  16,20€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/barrel-grand-modele-assiette/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel grand modèle assiettes"
                                    src="../../markdown-pages/barrel-grand-modele-assiette.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Barrel grand modèle assiettes
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  23,40€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/barrel-grand-modele-verre/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Barrel grand modèle verres"
                                    src="../../markdown-pages/barrel-grand-modele-verre.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Barrel grand modèle verres
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  18,90€ TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-abat-jour/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton abat-jour"
                                    src="../../markdown-pages/carton-abat-jour.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton abat-jour
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  13,00 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-bouteilles/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton bouteilles"
                                    src="../../markdown-pages/carton-bouteilles.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton bouteilles
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  4,50 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-halogene/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton halogène"
                                    src="../../markdown-pages/carton-halogene.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton halogène
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  18,80 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-isotherme/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton isotherme"
                                    src="../../markdown-pages/carton-isotherme.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton isotherme
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  41,60 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/cartons-livres/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Cartons livres"
                                    src="../../markdown-pages/cartons-livres.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Cartons livres
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  2,00 € TTC (36,00€ TTC X 20)
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-standard-renforce/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton standard renforcé"
                                    src="../../markdown-pages/carton-standard-renforce.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton standard renforcé
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  2,50 € TTC (46,00€ TTC X 20)
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-tableaux/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton tableaux"
                                    src="../../markdown-pages/carton-tableaux.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton tableaux
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  20,00 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/carton-penderie/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Carton penderie"
                                    src="../../markdown-pages/carton-penderie.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Carton penderie
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  14,20 € TTC
                                </p>
                              </div>
                            </a>
                          </div>

                          <div className="group relative">
                            <a href="/boutique/cartons/conteneur-archives/">
                              <div className="rounded-3xl bg-gray-200  group-hover:opacity-75">
                                <div className="object-contain  rounded-3xl  object-center ">
                                  <StaticImage
                                    className="rounded-3xl  object-center"
                                    alt="Conteneur archives"
                                    src="../../markdown-pages/conteneur-archives.jpg"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 grid">
                                <div>
                                  <h3 className="text-normal text-art-marron-fonce">
                                    Conteneur archives
                                  </h3>
                                </div>
                                <p className="flex text-normal font-medium text-art-orange">
                                  6,90 € TTC
                                </p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default MdxPage;
